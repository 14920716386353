//
// User Login v5
//




// Include framework config
@import "../../../framework/config";

// Include demo config
// @import "sass/config.scss";

// Login Base
.kt-login.kt-login--v5 {
    height: auto;
    display: flex;
    width: 100%;
    background-size: cover;
    background-repeat: no-repeat; 
    align-items: stretch;

    // Buttons
    
    .btn {
        //height: 46px;
        text-align: center;
        padding-left: 3rem;
        padding-right: 3rem;
        margin-right: 2rem;
     
        &:last-child {
            margin-right: 0;
        }
    }

    // Left Panel
    .kt-login__left {
        display: flex;
        flex: 1;
        width: 50%;
        align-items: center;
        justify-content: center;

        .kt-login__wrapper {
            display: flex;
            width: 500px;

            .kt-login__content {
                text-align: center;
                padding: 1rem 1.5rem;

                .kt-login__title {
                    margin: 5rem 0 2rem 0;
                    color: kt-base-color(label, 3);
                    font-size: 1.4rem;
                    font-weight: 500;
                }

                .kt-login__desc {
                    color: kt-base-color(label, 2);
                    font-size: 1rem;
                }

                .kt-login__actions {
                    margin: 3.4rem 0 3rem 0;
                    text-align: center;
                }
            }
        }
    }

    .kt-login__divider {
        display: flex;
        align-items: center;
        justify-content: center;
    
        > div {
            height: 400px;
            background: #ffffff;
            width: 30px;
            box-shadow: -19px 0 35px -7px #F5F5F5;
        }
    }
    
    // Right Panel
    .kt-login__right {
        display: flex;
        flex: 1;
        width: 50%;
        justify-content: center;
        align-items: center;

        .kt-login__wrapper {
            width: 400px;
            padding: 1rem 1.5rem;

            .kt-login__head {
                text-align: center;
            }
    
            .kt-login__logo {
                text-align: center;
                margin: 0 auto 4rem auto;
            }
    
            .kt-login__title {
                text-align: center;
                font-size: 1.5rem;
                font-weight: 500;
                color: kt-base-color(label, 3);
            }
    
            .kt-login__desc {
                margin-top: 0.75rem;
                font-size: 1rem;
                font-weight: 400;
                color: kt-base-color(label, 2);
            }
    
            .kt-login__form {
                margin-top: 4rem;
    
                .form-group {
                    margin: 0;
                    padding: 0;
                }
    
                .form-control {
                    height: 46px;
                    border-radius: 0;
                    border: none;
                    border-bottom: 0.07rem solid #ebecf1 !important;
                    padding: 1rem 0;
                    margin-top: 0.1rem;
                    @include kt-input-placeholder(#93959e);
                    &:focus {
                        @include kt-input-placeholder(kt-brand-color());
                    }
                    &.form-control-last {
                        border-bottom: 0 !important;
                    }
                }
    
                .form-control.is-valid + .valid-feedback,
                .form-control.is-invalid + .invalid-feedback {
                    font-weight: 500;
                    font-size: 0.9rem;
                    padding-left: 1.6rem;
                }
            }
    
            .kt-login__extra {
                margin-top: 2rem;
                color: #585a62;
                .kt-checkbox {
                    font-size: 1rem;
                }
                .kt-link {
                    font-size: 1rem;
                }
            }
    
            .kt-login__actions {
                margin: 3rem 0 3rem 0;
                text-align: center;
            }
    
            .kt-login__account {
                text-align: center;
                .kt-login__account-msg {
                    font-size: 1.1rem;
                    font-weight: 500;
                }
                .kt-login__account-link {
                    font-size: 1.1rem;
                    font-weight: 500;
                }
            }
        }
    }

    // Login Modes  
    &.kt-login--signin {
        .kt-login__signin {
            display: block;
        }
        .kt-login__forgot {
            display: none;
        }
    }

    &.kt-login--forgot {
        .kt-login__signin {
            display: none;
        }
        .kt-login__forgot {
            display: block;
        }
    }
}

@include kt-tablet-and-mobile() {
    .kt-login.kt-login--v5 {
        flex-direction: column;
        
        .kt-login__left {
            flex-grow: 1;
            width: 100%;
            margin: 0 auto;
            padding-top: 2.5rem;

            .kt-login__wrapper {
                padding-bottom: 0;
                margin: 0 auto;
                flex-direction: column;

                .kt-login__contanier {
                    padding: 1rem;
                    width: 100%;
                    margin-top: 3rem;
                    .kt-login__content {
                        width: 100%;
                    }
                }
            }
        }

        .kt-login__right {
            flex-grow: 1;
            width: 100%;
            padding-top: 1.5rem;
            padding-bottom: 2.5rem;

            .kt-login__contanier {
                width: 100%;
                margin: 0 auto;
                padding: 1rem;
            
                .kt-login__actions {
                    .btn {
                        padding: 1rem 1.95rem;
                        margin-right: 0.5rem;

                        &:last-child {
                            margin-right: 0;
                        }
                    }
                }
            }
        }

        .kt-login__divider {
            display: flex;
            padding-top: 0;
            width: 60%;
            margin: 0 auto;
            align-items: center;
            justify-content: center;
                    
            > div {
                height: 30px;
                background: #ffffff;
                width: 100%;
                box-shadow: 0 -15px 30px -7px #F5F5F5;
            }
        }
    }
}
